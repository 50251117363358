import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StateType = {
  error?: Error,
  baseUrl: string,
  isSignedIn: boolean,
  gglClientId: string
}
const initialState: StateType = {
  error: undefined,
  baseUrl: "",
  isSignedIn: false,
  gglClientId: ""
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      console.log(action.payload);
    },
    clearError: (state) => {
      state.error = undefined;
    },
    signIn: (state) => {
      state.isSignedIn = true;
    },
    signOut: (state) => {
      state.isSignedIn = false;
    },
  },
})

export const { setError, clearError, signIn, signOut } = globalSlice.actions;
export default globalSlice.reducer;