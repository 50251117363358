import React,{ FormEvent, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";

const service = new Service();
const newValues = () => ({ email: "" });
const newErrors = () => ({ email: "" });

export default function ResetPassword() {
  const [values, setValues] = useState(newValues());
  const [errors, setErrors] = useState(newErrors());
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (!validateForm())
      return;

    service.resetPassword(values.email)
      .then(() => {
        setMessage("Reset email message was sent, please follow the instructions when you receive it.");
        setValues(newValues());
      })
      .catch(e => dispatch(setError(e)));
  }

  const validateForm = () => {
    let isValid = true;
    let err = newErrors();
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      err.email = "Invalid email";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl text-center mb-4">Reset your password</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Email
              <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} type="text"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.email}</div>
          </div>

          <div className="flex items-baseline justify-between mt-8">
            <button type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Reset
            </button>
          </div>
        </form>  
        <div className="break-words w-80 pt-8">{message}</div>
      </div>
    </div>
  );
}