import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { List, NestedList } from '../data/models';

export const rootId = '.';

type StateType = {
  items: { [id: string]: NestedList },
  selectedList?: string
}
const initialState: StateType = {
  items: { [rootId]: { id: rootId, name: '', children: [], parentId: '', activeTasks: 0 } },
  selectedList: undefined
}

export const listsSlice = createSlice({
  name: 'lists',
  initialState,

  reducers: {
    setItems: (state, action: PayloadAction<{ [id: string]: NestedList }>) => {
      state.items = action.payload;
    },
    moveItem: (state, action: PayloadAction<{ fromList: string, fromIdx: number, toList: string, toIdx: number }>) => {
      const { fromList, fromIdx, toList, toIdx } = action.payload;
      if (fromList === toList) {
        let list = state.items[toList].children;
        swap(list, fromIdx, toIdx);
      }
      else {
        let srcList = state.items[fromList].children;
        let item = srcList[fromIdx];
        srcList.splice(fromIdx, 1);
        let dstList = state.items[toList].children;
        dstList.splice(toIdx, 0, item);
      }
    },
    selectItem: (state, action: PayloadAction<string | null>) => {
      if (action.payload && state.items.hasOwnProperty(action.payload)) {
        state.selectedList = action.payload;
      } else if (state.items[rootId].children.length > 0) {
        state.selectedList = state.items[rootId].children[0];
      } else {
        state.selectedList = undefined;
      }
    },
    addItem: (state, action: PayloadAction<{ item: List, isLast: boolean }>) => {
      const { item, isLast } = action.payload;
      state.items[item.id] = { ...item, children: [] };

      if (isLast) {
        state.items[rootId].children.push(item.id);
      } else {
        state.items[rootId].children.unshift(item.id);
      }
    },
    deleteItem: (state, action: PayloadAction<string>) => {
      let item = state.items[action.payload];
      //let parent = state.items[item.parentId];
      let parent = state.items[rootId];
      let idx = parent.children.findIndex(i => i === item.id);
      parent.children.splice(idx, 1);
      delete state.items[action.payload];
    },
    updateItem: (state, action: PayloadAction<NestedList>) => {
      state.items[action.payload.id] = action.payload;
    },
    changeCount: (state, action: PayloadAction<{ list: string, diff: number }>) => {
      state.items[action.payload.list].activeTasks += action.payload.diff;
    }
  },
});

export const { setItems, selectItem, moveItem, deleteItem, addItem, updateItem, changeCount } = listsSlice.actions;
export default listsSlice.reducer;

function swap(arr: any[], from: number, to: number) {
  let temp = arr[from];
  arr[from] = arr[to];
  arr[to] = temp;
  return arr;
}