import React, { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { Service } from "../../data/Service";
import { Link, useNavigate } from "react-router-dom";
import Separator from "../../components/core/Separator";
import googleBtn from '../../assets/btn_google_signin_light_normal_web.png';
import { setError, signIn } from "../../state/globalSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const service = new Service();
const newValues = () => ({ email: "", password: "" });
const newErrors = () => ({ email: "", password: "" });

export default function Signin() {
  const [values, setValues] = useState(newValues());
  const [errors, setErrors] = useState(newErrors());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = useSelector((state: RootState) => state.global.baseUrl);
  const gglClientId = useSelector((state: RootState) => state.global.gglClientId);

  const redirectUrl = baseUrl + '/authcallback';
  const scope = "openid profile email";

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (!validateForm())
      return;

    service.signin(values.email, values.password)
      .then(() => {
        dispatch(signIn());
        navigate("/");
      })
      .catch(e => dispatch(setError(e)));
  }

  const validateForm = () => {
    let isValid = true;
    let err = newErrors();
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      err.email = "Invalid email";
      isValid = false;
    }
    if (values.password.trim().length === 0) {
      err.password = "Required field";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl text-center mb-4">Sign in to your account</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Email
              <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} type="text"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.email}</div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Password
              <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} type="password"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.password}</div>
          </div>
          <div className="flex items-baseline justify-between">
            <button type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Sign in
            </button>
            <Link className="text-sm text-blue-600 hover:underline" to='/reset-password'>Forgot password?</Link>
          </div>
          <Separator text="Or" />
          <div className="flex justify-center items-center w-full">
            <a href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${gglClientId}&response_type=code&scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUrl)}`}>
              <img src={googleBtn} />
            </a>
          </div>
          <Separator text="Or" />
          <div className="flex justify-center items-center w-full">
            New user?&nbsp;<Link className="text-sm text-blue-600 hover:underline" to='/signup'>Create an account</Link>
          </div>
        </form>
      </div>
    </div>
  );
}