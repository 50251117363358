import { hydrateRoot } from 'react-dom/client';
import App from './App'
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from "react-redux";
import { createStore } from './state/store';
import * as sw from './serviceWorkerRegistration';

declare global {
  interface Window { __INIT_STATE__: any; }
}

const initState = window.__INIT_STATE__;
const store = createStore(initState);

hydrateRoot(document.getElementById('root')!,
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

sw.register();

