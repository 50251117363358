import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Service } from "../../data/Service";
import { setError, signIn } from "../../state/globalSlice";

const service = new Service();

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (code && code.length > 0) {
      service.authCallback(code)
        .then(isNew => {
          if (isNew) {
            gtag('event', 'conversion', { 'send_to': 'AW-11000691960/B2S7CO6ixOYDEPj5xP0o' });
          }
          dispatch(signIn());
          navigate("/", { replace: true });
        })
        .catch(e => dispatch(setError(e)));
    }
  }, [code]);

  return (
    <></>
  );
}