import React from 'react';

type Props = {
  onClick?: () => void,
};

export default function CloseIcon({ onClick }: Props) {
  return (
    <svg width="16" height="16" fill="#9ca3af" viewBox="0 0 16 16" className='shrink-0'>
      <title>The task contains notes</title>
      <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
    </svg>
  );
}