import { Task, List, Profile, CreateTaskReq, CreateListReq } from "./models";

export class Service {
    async signin(email: string, password: string): Promise<void> {
        let response = await fetch(`/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });
        await handleError(response);
    }

    async signup(fullName: string, email: string, password: string): Promise<void> {
        let response = await fetch(`/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fullName, email, password })
        });
        await handleError(response);
    }

    async signout(): Promise<void> {
        let response = await fetch(`/auth/signout`, {
            method: 'POST'
        });
        await handleError(response);
    }

    async getProfile(): Promise<Profile> {
        let response = await fetch(`/auth/me`);
        await handleError(response);
        return await response.json();
    }

    async authCallback(code: string): Promise<boolean> {
        let response = await fetch(`/auth/callback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code })
        });
        await handleError(response);

        return response.status === 201;
    }

    async resetPassword(email: string): Promise<void> {
        let response = await fetch(`/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
        await handleError(response);
    }

    async newPassword(password: string, code: string): Promise<void> {
        let response = await fetch(`/auth/new-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password, code })
        });
        await handleError(response);
    }

    // Lists
    async getLists(): Promise<List[]> {
        let response = await fetch(`/api/lists`);
        await handleError(response);
        return await response.json();
    }

    async createList(req: CreateListReq): Promise<string> {
        let response = await fetch(`/api/lists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        await handleError(response);
        let id = response.headers.get('Location')?.split('/').pop();
        return id!;
    }

    async updateList(item: List): Promise<void> {
        let response = await fetch(`/api/lists/${item.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        });
        await handleError(response);
    }

    async deleteList(id: string): Promise<void> {
        let response = await fetch(`/api/lists/${id}`, {
            method: 'DELETE'
        });
        await handleError(response);
    }

    async moveList(id: string, listId: string, idx: number): Promise<void> {
        let response = await fetch(`/api/lists/${id}/move`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ listId, idx })
        });
        await handleError(response);
    }

    // Tasks
    async getTasks(listId: string): Promise<Task[]> {
        let response = await fetch(`/api/lists/${listId}/tasks`);
        await handleError(response);
        return await response.json();
    }

    async createTask(req: CreateTaskReq): Promise<string> {
        let response = await fetch(`/api/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        await handleError(response);
        let id = response.headers.get('Location')?.split('/').pop();
        return id!;
    }

    async updateTask(item: Task): Promise<void> {
        let response = await fetch(`/api/tasks/${item.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        });
        await handleError(response);
    }

    async deleteTask(id: string): Promise<void> {
        let response = await fetch(`/api/tasks/${id}`, {
            method: 'DELETE'
        });
        await handleError(response);
    }

    async moveTask(id: string, listId: string, idx?: number): Promise<void> {
        let response = await fetch(`/api/tasks/${id}/move`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idx, listId })
        });
        await handleError(response);
    }

    async contact(name: string, email: string, subject: string, message: string): Promise<void> {
        let response = await fetch(`/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, subject, message })
        });
        await handleError(response);
    }

    async subscribe(sub: PushSubscription) {
        let response = await fetch(`/api/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sub)
        });
        await handleError(response);
    }
}

async function handleError(resp: Response) {
    if (!resp.ok) {
        let body: any;
        const contentType = resp.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1)
            body = await resp.json();

        throw new HttpError(resp.status, resp.statusText, body);
    }
}

export class HttpError extends Error {
    public code: number;
    public body?: any;
    constructor(code: number, message: string, body?: any) {
        super(message)
        this.name = 'HttpError'
        this.code = code;
        this.body = body;
    }
}