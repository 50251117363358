export function moveElement<T>(arr: Array<T>, from: number, to: number) {
  if (to === from) return arr;

  var target = arr[from];
  var increment = to < from ? -1 : 1;

  for (var k = from; k != to; k += increment) {
    arr[k] = arr[k + increment];
  }
  arr[to] = target;
  return arr;
}

export function setTextareaHeight(el: HTMLTextAreaElement) {
  el.style.height = "auto";
  el.style.height = el.scrollHeight + "px";
}