import { DragEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Service } from '../../data/Service';
import { setError } from '../../state/globalSlice';
import { changeCount } from '../../state/listsSlice';
import { RootState } from '../../state/store';
import { deleteItem } from '../../state/tasksSlice';
import CloseIcon from '../icons/CloseIcon';
import OpenIcon from '../icons/OpenIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import EditListModal from './EditListModal';
import ListCommands from './ListCommands';
import ListItems from './ListItems';

const service = new Service();

type Props = { listId: string };

export default function ListItem({ listId }: Props) {
  const dispatch = useDispatch();
  const selectedList = useSelector((state: RootState) => state.lists.selectedList);
  const item = useSelector((state: RootState) => state.lists.items[listId]);
  const dragItem = useSelector((state: RootState) => state.tasks.dragItem);

  const isSelected = listId === selectedList;
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropping, setDropping] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const hasChildren = item.children.length > 0;

  const onItemClick = () => {
    if (hasChildren) {
      setIsCollapsed(!isCollapsed);
    }
    else {
      searchParams.set('l', listId);
      setSearchParams(searchParams);
    }
  };

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    if (!hasChildren) {
      evt.preventDefault();

      if (dragItem)
        setDropping(true);
    }
  };

  const onDragLeave = () => {
    if (dragItem)
      setDropping(false);
  };

  const onDrop = () => {
    if (dragItem && !hasChildren && !isSelected) {
      setDropping(false);
      service.moveTask(dragItem.id, listId, undefined)
        .then(() => {
          dispatch(deleteItem(dragItem.id));
          dispatch(changeCount({ list: dragItem.listId, diff: -1 }));
          dispatch(changeCount({ list: listId, diff: 1 }));
        })
        .catch(e => dispatch(setError(e)));
    }
  };

  return (
    <div data-id={listId} >
      <div className={`flex items-center ${dropping ? 'border-2 border-dashed rounded' : ''}`}
        onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
        <div className='pr-1 w-3' onClick={onItemClick}>
          {hasChildren && isCollapsed && <OpenIcon />}
          {hasChildren && !isCollapsed && <CloseIcon />}
        </div>
        <div className={`flex items-center w-full ${!dropping ? 'border-b-2 border-gray-200' : ''}`}>
          <div className={`flex-1 cursor-pointer ${isSelected ? "sm:font-bold" : ""}`} onClick={onItemClick}>
            {item.name}&nbsp;
          </div>
          {!hasChildren &&
            <span className='text-xs text-gray-400 font-semibold'>{item.activeTasks}</span>
          }
          <ListCommands item={item} isExternal={true} />
        </div>
      </div>
      {/* <div className={`ml-4`}>
        {!isCollapsed &&
          <ListItems node={node} />
        }
      </div> */}
    </div >
  );
}