import React, { FormEvent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Service } from '../data/Service';
import { setError } from '../state/globalSlice'

const service = new Service();
const newValues = () => ({ name: "", email: "", subject: "", message: "" });
const newErrors = () => ({ name: "", email: "", subject: "", message: "" });

export default function Contact() {
  const [values, setValues] = useState(newValues());
  const [errors, setErrors] = useState(newErrors());
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (!validateForm())
      return;

    service.contact(values.name, values.email, values.subject, values.message)
      .then(() => {
        setMessage("Successfully sent");
        setValues(newValues());
      })
      .catch(e => dispatch(setError(e)));
  }

  const validateForm = () => {
    let isValid = true;
    let err = newErrors();
    if (values.name.trim().length === 0) {
      err.name = "Required field";
      isValid = false;
    }
    if (values.email.trim().length > 0 && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      err.email = "Invalid email";
      isValid = false;
    }
    if (values.subject.trim().length === 0) {
      err.subject = "Required field";
      isValid = false;
    }
    if (values.message.trim().length === 0) {
      err.message = "Required field";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl text-center mb-4">Contact form</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Your name
              <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} type="text"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.name}</div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Email
              <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} type="text"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.email}</div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Subject
              <input value={values.subject} onChange={(e) => setValues({ ...values, subject: e.target.value })} type="text"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.subject}</div>
          </div>
          <div className="mb-8">
            <label className="block text-gray-700 text-sm font-bold">
              Message
              <textarea rows={6} value={values.message} onChange={(e) => setValues({ ...values, message: e.target.value })}
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.message}</div>
          </div>
          <div className="flex items-center justify-center">
            <button type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Submit
            </button>
          </div>
        </form>
        <div className="break-words w-80 pt-8">{message}</div>
      </div>
    </div>
  );
}
<div>
  Name
  Email
  Subject
  Message
</div>