import { FocusEvent, useEffect, useRef, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Task } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { changeCount } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import { clearCommands, deleteItem, setCopyItem, setCutItem, updateItem } from "../../state/tasksSlice";
import { setTextareaHeight } from "../../tools/helpers";
import Calendar from "../core/Calendar";
import Modal from "../core/Modal";
import CancelIcon from "../icons/CancelIcon";
import ClearIcon from "../icons/ClearIcon";
import GearIcon from "../icons/GearIcon";
import SaveIcon from "../icons/SaveIcon";
import TaskCommands from "./TaskCommands";

const service = new Service();

type Props = { item: Task, onClose: () => void };

export default function EditTaskModal({ item, onClose }: Props) {
  const dispatch = useDispatch();
  const [isTitleFocus, setIsTitleFocus] = useState(false);
  const [title, setTitle] = useState(item.title);
  const titleRef = useRef<HTMLTextAreaElement>(null);

  const [notes, setNotes] = useState(item.notes);
  const [isNotesFocus, setIsNotesFocus] = useState(false);
  const notesRef = useRef<HTMLTextAreaElement>(null);

  const [reminder, setReminder] = useState(item.reminder ? new Date(item.reminder) : undefined);
  const [showCal, setShowCal] = useState(false);
  const [isDelayedSave, setIsDelayedSave] = useState(false);

  useEffect(() => {
    setTextareaHeight(titleRef.current!);
    if (notesRef.current)
      setTextareaHeight(notesRef.current);
  }, [title, notes]);

  useEffect(() => {
    if (isTitleFocus && titleRef.current) {
      titleRef.current.focus();
    }
  }, [isTitleFocus]);

  useEffect(() => {
    if (isNotesFocus && notesRef.current) {
      const end = notesRef.current.value.length;
      notesRef.current.setSelectionRange(end, end);
      notesRef.current.focus();
    }
  }, [isNotesFocus]);

  useEffect(() => {
    if (isDelayedSave) {
      save();
      setIsDelayedSave(false);
    }
  }, [isDelayedSave]);

  const save = () => {
    if (!validate())
      return;

    let notifValue = reminder ? (new Date(reminder)).toISOString() : undefined;
    let clone = { ...item, title, notes, reminder: notifValue };
    service.updateTask(clone)
      .then(() => {
        dispatch(updateItem(clone));
      })
      .catch(e => dispatch(setError(e)));
  };

  const onBlur = (evt: FocusEvent) => {
    let btn = evt.relatedTarget as HTMLButtonElement;
    if (btn?.name === "btnUndo") {
      setTitle(item.title);
      setNotes(item.notes);
    }
    else {
      save();
    }
    setIsTitleFocus(false);
    setIsNotesFocus(false);
  };

  const validate = () => {
    return title.trim().length > 0;
  };

  const onTitleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if ((evt.nativeEvent as InputEvent).inputType === "insertLineBreak")
      return;
    setTitle(evt.target.value);
  };

  const onNotesChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(evt.target.value);
  };

  const onOpenReminder = async () => {
    if (!('PushManager' in window)) {
      dispatch(setError(new Error("Push notifications aren't supported on this browser")));
      return;
    }
    const permissionResult = await Notification.requestPermission();
    if (permissionResult !== 'granted') {
      dispatch(setError(new Error("Permission to send notifications should be granted")));
      return;
    }

    setShowCal(true);
    let subscription = await getSubscription();
    await service.subscribe(subscription);
    // on load the server will check subscriptions
  };

  const onSetReminder = (d: Date) => {
    setReminder(d);
    setIsDelayedSave(true);
    setShowCal(false);
  };

  const onClearReminder = () => {
    setReminder(undefined);
    setIsDelayedSave(true);
  };

  return (
    <Modal onClose={onClose} width={36} height={24}
      content={
        <div className='flex flex-col gap-7'>
          <div className="flex">
            <span className="flex-1 font-semibold text-sm">TASK DETAILS</span>
            <TaskCommands item={item} isExternal={false} onAction={onClose} />
          </div>
          <div className={`border-2 rounded-lg p-1 pl-3 relative flex w-full ${isTitleFocus ? 'border-gray-500' : 'border-gray-100'}`}>
            <div className='absolute -top-3 left-2 text-gray-500 text-xs font-semibold bg-white px-1'>
              Title
            </div>
            <div onFocus={() => setIsTitleFocus(true)} onBlur={onBlur} className="flex w-full">
              <textarea rows={1} value={title} onChange={onTitleChange} ref={titleRef}
                className="appearance-none outline-none w-full max-h-20 resize-none" />
              {isTitleFocus &&
                <div className='absolute -bottom-5 right-2 flex bg-gray-500 flex rounded-b-lg items-center'>
                  <button type="button" name="btnUndo" className="px-1 pb-0.5 text-xs text-white">Undo</button>
                </div>
              }
            </div>
          </div>

          {item.notes && item.notes.trim().length > 0 || isNotesFocus ?
            <div className={`border-2 rounded-lg p-1 pl-3 relative flex w-full ${isNotesFocus ? 'border-gray-500' : 'border-gray-100'}`}>
              <div className='absolute -top-3 left-2 text-gray-500 text-xs font-semibold bg-white px-1'>
                Notes
              </div>
              <div onFocus={() => setIsNotesFocus(true)} onBlur={onBlur} className="flex w-full">
                <textarea value={notes ?? ""} rows={4} onChange={onNotesChange} ref={notesRef}
                  className="appearance-none outline-none w-full max-h-64 resize-none" />
                {isNotesFocus &&
                  <div className='absolute -bottom-5 right-2 flex bg-gray-500 flex rounded-b-lg items-center'>
                    <button type="button" name="btnUndo" className="px-1 pb-0.5 text-xs text-white">Undo</button>
                  </div>
                }
              </div>
            </div> :
            <div className='text-gray-500 text-xs font-semibold cursor-pointer w-fit' onClick={() => setIsNotesFocus(true)}>
              + ADD NOTES
            </div>
          }

          {reminder ?
            <div className='border-2 border-gray-100 rounded-lg p-1 pl-3 relative w-fit'>
              <div className='absolute -top-3 left-2 text-gray-500 text-xs font-semibold bg-white px-1'>
                Reminder
              </div>
              <div className="flex items-center gap-2">
                <span onClick={onOpenReminder} className='cursor-pointer'>
                  {reminder.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </span>
                <span onClick={onClearReminder}><CancelIcon /></span>
              </div>
            </div> :
            <div className='text-gray-500 text-xs font-semibold cursor-pointer' onClick={onOpenReminder}>
              + ADD REMINDER
            </div>
          }

          {showCal &&
            <Calendar date={reminder ?? new Date()} onSelect={onSetReminder} onCancel={() => setShowCal(false)} />
          }
        </div>
      }
      footer={
        <div className="flex justify-end">
          <span className='text-blue-500 font-semibold cursor-pointer p-2 text-sm' onClick={onClose}>OK</span>
        </div>
      }
    />
  );
}

async function getSubscription() {
  let registration = await navigator.serviceWorker.ready;

  const opts = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array('BH5JYcLa44SEwTxvRB0kP_QP-t_HlEP1rsqQBM1UmrUcQ33lklPsZzYyn98i04S3Mi-eig37T_0dbW-QWEEwzI0')
  };

  let subscription = await registration.pushManager.getSubscription();

  if (!subscription) {
    subscription = await registration.pushManager.subscribe(opts);
  }
  return subscription;
}

function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}