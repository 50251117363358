import { useSearchParams } from 'react-router-dom';
import Lists from '../components/lists/Lists';
import Tasks from '../components/tasks/Tasks';

export default function PrivateHome() {
  const [searchParams] = useSearchParams();
  let searchListId = searchParams.get('l');

  return (
    <div className='w-full flex flex-col'>
      <div className="grid-full-1 flex-1 transition-height ease-in-out duration-300 justify-items-end">
        <div className="grid-area-1 bg-gray-200 flex w-full">
          <div className='bg-white m-4 p-4 pl-2 rounded flex flex-1 sm:basis-1/3'>
            <Lists />
          </div>
          <div className='bg-white m-4 ml-0 p-4 rounded flex-1 hidden sm:basis-2/3 sm:flex'>
            <Tasks />
          </div>
        </div>

        <div className={`sm:hidden grid-area-1 z-10 bg-gray-200 transition-width ease-in-out duration-300 flex ${searchListId ? 'w-full h-full' : 'w-0 h-[48rem] overflow-hidden'}`}>
          <div className='bg-white m-4 p-4 rounded flex flex-1'>
            <Tasks />
          </div>
        </div>
      </div>
    </div>
  );
}