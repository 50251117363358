import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../assets/hero.png';

export default function PublicHome() {
  const data = [
    { message: "I can manage multiple lists and this is great!", name: "J. Foster" },
    { message: "My life became more organized after I've started using this app.", name: "A. Brown" },
    { message: "I really like it... It is so simple, without any complications.", name: "P. Scott" }
  ];

  return (
    <div className='flex flex-col w-full'>
      <main className='h-96 bg-stone-400 flex items-center justify-around flex-wrap p-8 bg-right bg-[url("/assets/hero.png")] lg:bg-[url("/assets/hero-lg.png")] xl:bg-[url("/assets/hero-xl.png")] bg-no-repeat'>
        <div className='hidden md:grow-[2] md:block' />
        <div className='flex flex-col gap-6'>
          <h1 className='text-5xl text-white'>Just simple</h1>
          <h2 className='text-2xl text-gray-600'>Create your todo lists and manage your tasks easily</h2>
        </div>
        <div className='hidden md:grow-[2] md:block' />
        <Link to={'/signin'}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Start now
        </Link>
        <div className='hidden md:grow-[6] md:block' />
      </main>

      <section className='min-h-64 grow shrink-0 bg-white flex items-center justify-evenly flex-wrap gap-4 p-4'>
        {data.map((i, idx) =>
          <div key={idx} className='w-60 h-60 p-6 bg-gray-100 shadow-lg rounded flex flex-col gap-6 items-center justify-center'>
            <span className='text-base'>{i.message}</span>
            <span className='text-sm text-gray-500'>{i.name}</span>
          </div>
        )}
      </section>
    </div>
  );
}