import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Task } from '../../data/models';
import { Service } from '../../data/Service';
import { setError } from '../../state/globalSlice';
import { changeCount } from '../../state/listsSlice';
import { RootState } from '../../state/store';
import { clearDragItem, setDragItem, updateItem } from '../../state/tasksSlice';
import CheckBox from '../core/CheckBox';
import BellIcon from '../icons/BellIcon';
import NotesIcon from '../icons/NotesIcon';
import EditTaskModal from './EditTaskModal';
import TaskCommands from './TaskCommands';

const service = new Service();

type Props = { item: Task };

export default function TaskItem({ item }: Props) {
  const dispatch = useDispatch();
  const cutItem = useSelector((state: RootState) => state.tasks.cutItem);
  const copyItem = useSelector((state: RootState) => state.tasks.copyItem);
  const [showDetails, setShowDetails] = useState(false);

  const onDone = () => {
    let clone = { ...item, done: !item.done };
    service.updateTask(clone)
      .then(() => {
        dispatch(updateItem(clone));
        dispatch(changeCount({ list: clone.listId, diff: clone.done ? -1 : 1 }));
      })
      .catch(e => dispatch(setError(e)));
  };

  let textStyle = cutItem?.id === item.id ? 'text-gray-400' :
    copyItem?.id === item.id ? 'font-semibold' : '';

  return (
    <div className='flex items-center border-b-2 border-gray-200' data-id={item.id}
      onDragStart={() => dispatch(setDragItem(item))} onDragEnd={() => dispatch(clearDragItem())}>
      <span className='pr-2' onClick={onDone}>
        <CheckBox value={item.done} />
      </span>
      <div className={`flex-1 cursor-pointer flex items-center relative ${item.done ? 'line-through text-gray-400' : ''}`} onClick={() => setShowDetails(true)} >
        <span className={textStyle}>{item.title}</span>
      </div>
      <div className='flex gap-1 items-center'>
        {item.reminder && new Date(item.reminder) > new Date() &&
          <BellIcon />
        }
        {item.notes && item.notes.trim().length > 0 &&
          <NotesIcon />
        }
      </div>
      <TaskCommands item={item} isExternal={true} />
      {showDetails &&
        <EditTaskModal item={item} onClose={() => setShowDetails(false)} />
      }
    </div>
  );
}