import { useDispatch } from "react-redux";
import { NestedList } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from '../../state/globalSlice';
import { moveItem } from "../../state/listsSlice";
import Sortable, { ChangeEvent } from "../core/Sortable";
import ListItem from "./ListItem";

const service = new Service();

type Props = { node: NestedList };

export default function ListItems({ node }: Props) {
  const dispatch = useDispatch();

  const onChange = (evt: ChangeEvent) => {
    const id = evt.item.dataset.id!;
    dispatch(moveItem({
      fromList: evt.oldList,
      fromIdx: evt.oldIdx,
      toList: evt.newList,
      toIdx: evt.newIdx
    }));

    service.moveList(id, evt.newList, evt.newIdx)
      .catch(e => dispatch(setError(e)));
  };

  return (
    <Sortable id={node.id} group='nested' className='flex flex-col gap-5' onChange={onChange}
      options={{ delay: 200, delayOnTouchOnly: true }}>
      {node.children.map(i =>
        <ListItem key={i} listId={i} />
      )}
    </Sortable>
  );
}