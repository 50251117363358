import React from 'react';

type Props = {
  z: number,
  onClick: () => void,
};

export default function PlusFab({ z, onClick }: Props) {
  return (
    <div className='absolute h-full w-0 top-0 right-0 flex justify-end items-end' style={{ zIndex: z }}>
      <button onClick={onClick} className="sticky bottom-12 right-4 mb-12 mr-4 bg-blue-600 min-w-[3rem] h-[3rem] rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl">
        <svg viewBox="0 0 20 20" enableBackground="new 0 0 20 20" className="w-6 h-6 inline-block">
          <path fill="#FFFFFF" d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                                    C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                                    C15.952,9,16,9.447,16,10z" />
        </svg>
      </button>
    </div>
  );
}