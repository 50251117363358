import { useEffect, useState } from 'react';
import DownIcon from '../icons/DownIcon';
import NextIcon from '../icons/NextIcon';
import PrevIcon from '../icons/PrevIcon';
import UpIcon from '../icons/UpIcon';
import Modal from './Modal';

type Props = {
  date?: Date,
  onSelect: (d: Date) => void,
  onCancel: () => void
};

// add min date support
export default function Calendar({ date = new Date(), onSelect, onCancel }: Props) {
  const [current, setCurrent] = useState(date);
  const [monOffset, setMonOffset] = useState(0);

  let firstMonDay = new Date(date.getFullYear(), current.getMonth() + monOffset, 1);
  let lastMonDay = new Date(date.getFullYear(), current.getMonth() + monOffset + 1, 0);
  let lastMonDayNum = lastMonDay.getDate();

  const days = [];
  for (var i = 1; i <= lastMonDayNum; i++) {
    days.push(i);
  }

  let lang = navigator.language;

  let firstDay: { [key: string]: number } = {
    "001": 1, "AD": 1, "AE": 6, "AF": 6, "AG": 0, "AI": 1, "AL": 1, "AM": 1, "AN": 1, "AR": 1, "AS": 0, "AT": 1, "AU": 0, "AX": 1, "AZ": 1, "BA": 1,
    "BD": 0, "BE": 1, "BG": 1, "BH": 6, "BM": 1, "BN": 1, "BR": 0, "BS": 0, "BT": 0, "BW": 0, "BY": 1, "BZ": 0, "CA": 0, "CH": 1, "CL": 1, "CM": 1,
    "CN": 0, "CO": 0, "CR": 1, "CY": 1, "CZ": 1, "DE": 1, "DJ": 6, "DK": 1, "DM": 0, "DO": 0, "DZ": 6, "EC": 1, "EE": 1, "EG": 6, "ES": 1, "ET": 0,
    "FI": 1, "FJ": 1, "FO": 1, "FR": 1, "GB": 1, "GB-alt-variant": 0, "GE": 1, "GF": 1, "GP": 1, "GR": 1, "GT": 0, "GU": 0, "HK": 0, "HN": 0, "HR": 1, "HU": 1,
    "ID": 0, "IE": 1, "IL": 0, "IN": 0, "IQ": 6, "IR": 6, "IS": 1, "IT": 1, "JM": 0, "JO": 6, "JP": 0, "KE": 0, "KG": 1, "KH": 0, "KR": 0, "KW": 6,
    "KZ": 1, "LA": 0, "LB": 1, "LI": 1, "LK": 1, "LT": 1, "LU": 1, "LV": 1, "LY": 6, "MC": 1, "MD": 1, "ME": 1, "MH": 0, "MK": 1, "MM": 0, "MN": 1,
    "MO": 0, "MQ": 1, "MT": 0, "MV": 5, "MX": 0, "MY": 1, "MZ": 0, "NI": 0, "NL": 1, "NO": 1, "NP": 0, "NZ": 1, "OM": 6, "PA": 0, "PE": 0, "PH": 0,
    "PK": 0, "PL": 1, "PR": 0, "PT": 0, "PY": 0, "QA": 6, "RE": 1, "RO": 1, "RS": 1, "RU": 1, "SA": 0, "SD": 6, "SE": 1, "SG": 0, "SI": 1, "SK": 1,
    "SM": 1, "SV": 0, "SY": 6, "TH": 0, "TJ": 1, "TM": 1, "TR": 1, "TT": 0, "TW": 0, "UA": 1, "UM": 0, "US": 0, "UY": 1, "UZ": 1, "VA": 1, "VE": 0,
    "VI": 0, "VN": 1, "WS": 0, "XK": 1, "YE": 0, "ZA": 0, "ZW": 0
  }

  const locale = new Intl.Locale(lang);
  let dowOffset = firstDay[locale.region ?? "US"];
  let monthName = firstMonDay.toLocaleString(lang, { month: 'long' });
  let startDow = (7 + firstMonDay.getDay() - dowOffset) % 7 + 1;

  let weekDays = [];
  for (let i = 0; i < 7; i++) {
    let name = (new Date(1970, 1, 1 + i + dowOffset)).toLocaleString(lang, { weekday: 'short' });
    weekDays.push(name);
  }

  let hours = current.getHours()
  let minutes = current.getMinutes();
  let fullHours = hours > 9 ? hours : '0' + hours;
  let fullMinutes = minutes > 9 ? minutes : '0' + minutes;

  let now = new Date();
  const getClasses = (day: number) => {
    let classes: string[] = [];
    let isToday = day === now.getDate() && firstMonDay.getMonth() === now.getMonth() && firstMonDay.getFullYear() === now.getFullYear();
    let isSelected = day === current.getDate() && firstMonDay.getMonth() === current.getMonth() && firstMonDay.getFullYear() === current.getFullYear();
    let wasPassed = new Date(now.getFullYear(), now.getMonth(), now.getDate()) > new Date(firstMonDay.getFullYear(), firstMonDay.getMonth(), day);

    if (isToday)
      classes.push('bg-orange-100');

    if (isSelected)
      classes.push('border-black');

    if (wasPassed)
      classes.push('text-gray-300 cursor-not-allowed');
    else
      classes.push('cursor-pointer');

    return classes.join(' ');
  };

  const onDaySelect = (day: number) => {
    let wasPassed = new Date(now.getFullYear(), now.getMonth(), now.getDate()) > new Date(firstMonDay.getFullYear(), firstMonDay.getMonth(), day);
    if (!wasPassed) {
      setCurrent(new Date(firstMonDay.getFullYear(), firstMonDay.getMonth(), day, current.getHours(), current.getMinutes()));
      setMonOffset(0);
    }
  };

  const onTimeChange = (part: string, dir: number) => {
    switch (part) {
      case 'h':
        setCurrent(new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours() + dir, current.getMinutes()));
        break;
      case 'm':
        let minutes = getStep(current.getMinutes(), 5, dir);
        setCurrent(new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(), minutes));
        break;
    }
  };

  const onAccept = () => {
    if (new Date() < current)
      onSelect(current);
  };

  const getStep = (num: number, step: number, dir: number) => {
    let rem = num % step;
    let add = rem === 0 ? dir * step
      : dir > 0 ? step - rem
        : -rem;
    return num + add;
  };

  return (
    <Modal onClose={onCancel} width={24} height={22}
      content={
        <div className='flex gap-6 select-none'>
          <div className='w-64 flex flex-col gap-2'>
            <div className='flex items-center'>
              <PrevIcon onClick={() => setMonOffset(monOffset - 1)} />
              <div className='flex-1 flex justify-center'>{monthName} {firstMonDay.getFullYear()}</div>
              <NextIcon onClick={() => setMonOffset(monOffset + 1)} />
            </div>
            <div className='grid grid-cols-7 justify-items-center'>
              {weekDays.map(i =>
                <div key={i} className='font-semibold text-xs'>{i}</div>
              )}
            </div>
            <div className='grid grid-cols-7 place-items-center h-48'>
              {days.map(i =>
                <div key={i} style={{ gridColumnStart: i === 1 ? startDow : undefined }}
                  onClick={() => onDaySelect(i)}
                  className={`flex w-6 h-6 items-center justify-center rounded-full border ${getClasses(i)}`}>
                  {i}
                </div>
              )}
            </div>
          </div>

          <div className='flex items-center'>
            <div className='w-16 h-16 grid grid-cols-3 place-items-center text-2xl'>
              <UpIcon onClick={() => onTimeChange('h', 1)} />
              <span className='row-span-3 mb-2'>:</span>
              <UpIcon onClick={() => onTimeChange('m', 1)} />
              <span>{fullHours}</span>
              <span>{fullMinutes}</span>
              <DownIcon onClick={() => onTimeChange('h', -1)} />
              <DownIcon onClick={() => onTimeChange('m', -1)} />
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex items-center justify-between text-xs font-semibold mt-4">
          <span className='cursor-pointer text-gray-500 p-2' onClick={onCancel}>CANCEL</span>
          <span className='cursor-pointer text-blue-500 p-2' onClick={onAccept}>SELECT</span>
        </div>
      }
    />
  );
}
//https://github.com/unicode-cldr/cldr-core/blob/master/supplemental/weekData.json