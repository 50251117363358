type Props = {
  onClick?: () => void,
};

export default function CutIcon({ onClick }: Props) {
  return (
    <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className='cursor-pointer' onClick={onClick}>
      <title>Cut</title>
      <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
    </svg>
  );
}