import { useState, MouseEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NestedList } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { changeCount, deleteItem, selectItem } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import { addItem as addTask, deleteItem as deleteTask, clearCommands } from "../../state/tasksSlice";
import DeleteIcon from "../icons/DeleteIcon";
import DetailsIcon from "../icons/DetailsIcon";
import PasteIcon from "../icons/PasteIcon";
import ThreeDotsIcon from "../icons/ThreeDotsIcon";
import EditListModal from "./EditListModal";

const service = new Service();

type Props = {
  item: NestedList,
  isExternal: boolean,
  onAction?: () => void
};

export default function ListCommands({ item, isExternal, onAction }: Props) {
  const dispatch = useDispatch();
  const cutItem = useSelector((state: RootState) => state.tasks.cutItem);
  const copyItem = useSelector((state: RootState) => state.tasks.copyItem);
  const selectedList = useSelector((state: RootState) => state.lists.selectedList);
  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const hasChildren = item.children.length > 0;

  const onPaste = () => {
    if (cutItem && cutItem.id !== item.id) {
      service.moveTask(cutItem.id, item.id, undefined)
        .then(() => {
          if (selectedList === item.id) {
            dispatch(addTask({ item: cutItem, isLast: true }));
          }
          if (selectedList === cutItem.listId) {
            dispatch(deleteTask(cutItem.id));
          }
          dispatch(clearCommands());
          dispatch(changeCount({ list: item.id, diff: 1 }));
          dispatch(changeCount({ list: cutItem.listId, diff: -1 }));
          onAction?.();
        })
        .catch(e => dispatch(setError(e)));
    }

    if (copyItem) {
      let clone = { ...copyItem, listId: item.id, idx: -1 };
      service.createTask(clone)
        .then(id => {
          clone.id = id;
          if (selectedList === item.id) {
            dispatch(addTask({ item: clone, isLast: true }));
          }
          dispatch(clearCommands());
          dispatch(changeCount({ list: item.id, diff: 1 }));
          onAction?.();
        })
        .catch(e => dispatch(setError(e)));
    }
  };

  const onDelete = () => {
    service.deleteList(item.id)
      .then(() => {
        dispatch(deleteItem(item.id));
        dispatch(selectItem(null));
        onAction?.();
      })
      .catch(e => dispatch(setError(e)));
  };

  const onShowMenu = () => {
    if (!showMenu) {
      setTimeout(() => {
        window.addEventListener("click", () => setShowMenu(false), { once: true });
      }, 0);
    }
    setShowMenu(val => !val);
  };

  return (
    <>
      <div className='p-1 pr-0 realative' onClick={onShowMenu}>
        <ThreeDotsIcon />
        {showMenu &&
          <div className={`absolute bg-white w-24 right-0 z-20 shadow-xl flex flex-col divide-y divide-slate-200 text-xs cursor-pointer`}>
            {isExternal &&
              <div className='px-2 py-3 flex gap-2' onClick={() => setShowDetails(true)}>
                <DetailsIcon />DETAILS
              </div>
            }
            {!hasChildren && (copyItem || cutItem) &&
              <div className='px-2 py-3 flex gap-2' onClick={onPaste}>
                <PasteIcon />PASTE
              </div>
            }
            <div className='px-2 py-3 text-red-500 flex gap-2' onClick={onDelete}>
              <DeleteIcon />DELETE
            </div>
          </div>
        }
      </div>
      {showDetails &&
        <EditListModal item={item} onClose={() => setShowDetails(false)} />
      }
    </>
  );
}