import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { List, NestedList } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from '../../state/globalSlice';
import { rootId, selectItem, setItems } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import PlusFab from "../core/PlusFab";
import ListItems from "./ListItems";
import NewList from "./NewListModal";

const service = new Service();

export default function Lists() {
  const dispatch = useDispatch();
  const selectedList = useSelector((state: RootState) => state.lists.selectedList);
  const root = useSelector((state: RootState) => state.lists.items[rootId]);

  const [searchParams] = useSearchParams();
  let searchListId = searchParams.get('l');
  const [showNewList, setShowNewList] = useState(false);

  useEffect(() => {
    service.getLists()
      .then(data => {
        let map = castToNestedMap(data);
        dispatch(setItems(map));
        dispatch(selectItem(searchListId));
      })
      .catch(e => dispatch(setError(e)));
  }, []);

  useEffect(() => {
    if (selectedList !== searchListId) {
      dispatch(selectItem(searchListId));
    }
  }, [searchParams]);

  return (
    <>
      <div className="relative w-full">
        <h1 className="text-2xl text-gray-400">Lists</h1>
        <div className='flex flex-col gap-1 mt-6'>
          <ListItems node={root} />
        </div>
        <PlusFab z={1} onClick={() => setShowNewList(true)} />
      </div>
      {showNewList &&
        <NewList onClose={() => setShowNewList(false)} />
      }
    </>
  );
}

function castToNestedMap(data: List[]) {
  let root: NestedList = { id: rootId, name: '', parentId: '', children: [], activeTasks: 0 };
  let map: { [id: string]: NestedList } = { [rootId]: root };

  for (const i of data) {
    map[i.id] = { ...i, children: [] };
  }

  for (const i of data) {
    let parent = map[i.parentId];
    if (parent) {
      parent.children.push(i.id);
    } else {
      root.children.push(i.id);
    }
  }
  return map;
}