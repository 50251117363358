import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateTaskReq } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { changeCount } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import { addItem } from "../../state/tasksSlice";
import Modal from "../core/Modal";
import AlertIcon from "../icons/AlertIcon";
import ItemFirstIcon from "../icons/ItemFirstIcon";
import ItemLastIcon from "../icons/ItemLastIcon";

const service = new Service();

type Props = { onClose: () => void };

export default function NewTaskModal({ onClose }: Props) {
  const selectedList = useSelector((state: RootState) => state.lists.selectedList);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [isError, setIsError] = useState(false);
  const titleRef = useRef<HTMLInputElement>(null);
  const [addLast, setAddLast] = useState(true);

  useEffect(() => {
    if (title.trim().length === 0) {
      titleRef.current?.focus();
    }
    else if (isError) {
      setIsError(false);
    }
  }, [title]);

  const onSave = (next: boolean) => {
    if (!validate()) {
      setIsError(true);
      return;
    }

    let createReq: CreateTaskReq = { title, listId: selectedList!, done: false, idx: addLast ? -1 : 0 };
    service.createTask(createReq)
      .then(id => {
        let created = { ...createReq, id };
        dispatch(addItem({ item: created, isLast: addLast }));
        dispatch(changeCount({ list: selectedList!, diff: 1 }));

        if (next)
          setTitle("");
        else
          onClose();
      })
      .catch(e => dispatch(setError(e)));
  };

  const validate = () => {
    return title.trim().length > 0;
  };

  return (
    <Modal onClose={onClose} width={28} height={12}
      content={
        <div className='flex flex-col'>
          <div className="flex">
            <div className="flex-1 font-semibold text-sm">NEW TASK</div>
            <div className="flex pt-4 align-end gap-2">
              <div onClick={() => setAddLast(true)}>
                <ItemLastIcon fill={addLast ? 'rgb(59 130 246)' : 'rgb(156 163 175)'} />
              </div>
              <div onClick={() => setAddLast(false)}>
                <ItemFirstIcon fill={!addLast ? 'rgb(59 130 246)' : 'rgb(156 163 175)'} />
              </div>
            </div>
          </div>
          <div className="relative">
            <input value={title} onChange={e => setTitle(e.target.value)} type="text" placeholder="Title" ref={titleRef}
              className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            {isError &&
              <div className="absolute top-5 right-2"><AlertIcon /></div>
            }
          </div>
        </div>
      }
      footer={
        <div className="flex items-center justify-between text-xs font-semibold">
          <span className='cursor-pointer text-blue-500 p-2' onClick={() => onSave(true)}>NEXT</span>
          <span className='cursor-pointer text-gray-500 p-2' onClick={onClose}>CANCEL</span>
          <span className='cursor-pointer text-blue-500 p-2' onClick={() => onSave(false)}>OK</span>
        </div>
      }
    />
  );
}