import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../state/store';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='p-8'>
      <h1 className='text-4xl'>About</h1>
      <br />
      Nexto.do is free and non-profit project for tasks management.
      <br />
      <br />
      Current version {process.env.N_VERSION}
      <br />
      <br />
      {/* Built by:<br/>
      Golang, Postgres, React, TailwindCSS, GCP
      <br />
      <br /> */}
      In the upcoming releases:
      <ul className="list-disc pl-4">
        <li>Nested lists</li>
        <li>Offline mode support</li>
        <li>Task priority</li>
        <li>Task blocking ability</li>
        <li>Multiple users support</li>
        <li>Make the project open-source and manage issues with Github</li>
        <li>Add much more...</li>
      </ul>
      <br />
      <br />
      For proposals, bugs or other topics please contact us <Link className='text-blue-500' to={'/contact'}>here</Link>.
    </div>
  );
}