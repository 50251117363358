import { combineReducers, configureStore } from '@reduxjs/toolkit';
import listsReducer from './listsSlice';
import tasksReducer from './tasksSlice';
import globalReducer from './globalSlice';

export const rootReducer = combineReducers({
  lists: listsReducer,
  tasks: tasksReducer,
  global: globalReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const createStore = (initState: any) => configureStore({
  reducer: rootReducer,
  preloadedState: initState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});