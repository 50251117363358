const titles: { [path: string]: string } = {
  '/': "Nexto.do",
  '/terms': "Nexto.do - Terms and Conditions",
  '/privacy': "Nexto.do - Privacy Policy",
  '/about': "Nexto.do - About",
  '/contact': "Nexto.do - Contact",
  '/reset-password': "Nexto.do - Reset password",
  '/new-password': "Nexto.do - New password",
  '/signin': "Nexto.do - Sign-In",
  '/signup': "Nexto.do - Sign-Up"
};
export default titles;