import React, { FormEvent, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Service } from "../../data/Service";
import { setError, signIn } from "../../state/globalSlice";

const service = new Service();
const newValues = () => ({ password: "", repassword: "" });
const newErrors = () => ({ password: "", repassword: "" });

export default function NewPassword() {
  const [values, setValues] = useState(newValues());
  const [errors, setErrors] = useState(newErrors());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (!validateForm())
      return;

    service.newPassword(values.password, searchParams.get("code")!)
      .then(() => {
        dispatch(signIn());
        navigate("/");
      })
      .catch(e => dispatch(setError(e)));
  }

  const validateForm = () => {
    let isValid = true;
    let err = newErrors();
    if (values.password.trim().length === 0) {
      err.password = "Required field";
      isValid = false;
    }
    if (values.password !== values.repassword) {
      err.repassword = "Password doesn't match";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };


  return (
    <div className="flex items-center justify-center w-full">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl text-center mb-4">Set a new password</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              New password
              <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} type="password"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.password}</div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold">
              Retype password
              <input value={values.repassword} onChange={(e) => setValues({ ...values, repassword: e.target.value })} type="password"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </label>
            <div className="text-red-400 text-sm">{errors.repassword}</div>
          </div>
          <div className="flex items-baseline justify-between">
            <button type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Change
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}