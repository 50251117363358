import React from "react";
import SortableJs, { SortableEvent, SortableOptions } from "sortablejs";
type Props = {
  children: JSX.Element[],
  id?: string,
  group?: string,
  className?: string,
  onChange: (evt: ChangeEvent) => void,
  options?: SortableOptions
};
type State = {};

export interface UpdateArgs {
  oldIdx: number,
  newIdx: number,
  list: string
};

export interface ChangeEvent {
  oldList: string,
  oldIdx: number,
  newList: string,
  newIdx: number,
  item: HTMLElement
};


export default class Sortable extends React.Component<Props, State> {
  sortableRef: React.RefObject<HTMLDivElement>;
  shouldUpdate: boolean;
  sortable?: SortableJs;

  constructor(props: Props) {
    super(props);
    this.sortableRef = React.createRef();
    this.shouldUpdate = true;
  }

  componentDidMount() {
    this.sortable = SortableJs.create(this.sortableRef!.current!, {
      sort: true,
      delay: 200,
      delayOnTouchOnly: true,
      onUpdate: this.onChnage.bind(this),
      onRemove: this.onChnage.bind(this),
      onAdd: this.onAdd.bind(this),
      group: this.props.group,
      ...this.props.options
    });
  }

  onAdd() {
    this.shouldUpdate = false;
  }

  onChnage(evt: SortableEvent) {
    let oldList = evt.from.dataset.id!;
    let newList = evt.to.dataset.id!;
    this.shouldUpdate = false;
    this.props.onChange?.({
      oldList: oldList,
      oldIdx: evt.oldIndex!,
      newList: newList,
      newIdx: evt.newIndex!,
      item: evt.item
    });
  }

  componentWillUnmount() {
    this.sortable?.destroy();
  }

  shouldComponentUpdate() {
    if (!this.shouldUpdate) {
      this.shouldUpdate = true;
      return false;
    }
    return true;
  }

  render() {
    return (
      <div ref={this.sortableRef} className={this.props.className} data-id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}