import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NestedList } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { changeCount, updateItem, deleteItem, selectItem } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import { addItem as addTask, deleteItem as deleteTask, clearCommands } from "../../state/tasksSlice";
import { setTextareaHeight } from "../../tools/helpers";
import Modal from "../core/Modal";
import CancelIcon from "../icons/CancelIcon";
import PasteIcon from "../icons/PasteIcon";
import SaveIcon from "../icons/SaveIcon";
import ListCommands from "./ListCommands";

const service = new Service();

type Props = { item: NestedList, onClose: () => void };

export default function EditListModal({ item, onClose }: Props) {
  const dispatch = useDispatch();
  const copyItem = useSelector((state: RootState) => state.tasks.copyItem);
  const cutItem = useSelector((state: RootState) => state.tasks.cutItem);
  const hasChildren = item.children.length > 0;

  const [isNameFocus, setIsNameFocus] = useState(false);
  const [name, setName] = useState(item.name);
  const nameRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTextareaHeight(nameRef.current!);
  }, [name]);

  useEffect(() => {
    if (isNameFocus && nameRef.current) {
      nameRef.current.focus();
    }
  }, [isNameFocus]);

  const save = () => {
    if (!validate())
      return;

    let clone = { ...item, name };
    service.updateList(clone)
      .then(() => {
        dispatch(updateItem(clone));
      })
      .catch(e => dispatch(setError(e)));
  };

  const onBlur = (evt: FocusEvent) => {
    let btn = evt.relatedTarget as HTMLButtonElement;
    if (btn?.name === "btnUndo") {
      setName(item.name);
    }
    else {
      save();
    }
    setIsNameFocus(false);
  };

  const validate = () => {
    return name.trim().length > 0;
  };

  const onNameChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if ((evt.nativeEvent as InputEvent).inputType === "insertLineBreak")
      return;
    setName(evt.target.value);
  };

  return (
    <Modal onClose={onClose} width={36} height={18}
      content={
        <div className='flex flex-col gap-7'>
          <div className="flex">
            <span className="flex-1 font-semibold text-sm">LIST DETAILS</span>
            <ListCommands item={item} isExternal={false} onAction={onClose} />
          </div>
          <div className={`border-2 rounded-lg p-1 pl-3 relative flex w-full ${isNameFocus ? 'border-gray-500' : 'border-gray-100'}`}>
            <div className='absolute -top-3 left-2 text-gray-500 text-xs font-semibold bg-white px-1'>
              Name
            </div>
            <div onFocus={() => setIsNameFocus(true)} onBlur={onBlur} className="flex w-full">
              <textarea value={name} rows={1} onChange={onNameChange} ref={nameRef}
                className="appearance-none outline-none w-full max-h-20 resize-none" />
              {isNameFocus &&
                <div className='absolute -bottom-5 right-2 flex bg-gray-500 flex rounded-b-lg items-center'>
                  <button type="button" name="btnUndo" className="px-1 pb-0.5 text-xs text-white">Undo</button>
                </div>
              }
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end">
          <span className='text-blue-500 font-semibold cursor-pointer p-2 text-sm' onClick={onClose}>OK</span>
        </div>
      }
    />
  );
}
