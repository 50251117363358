import { useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Task } from "../../data/models";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { changeCount } from "../../state/listsSlice";
import { RootState } from "../../state/store";
import { clearCommands, deleteItem, setCopyItem, setCutItem } from "../../state/tasksSlice";
import CopyIcon from "../icons/CopyIcon";
import CutIcon from "../icons/CutIcon";
import DeleteIcon from "../icons/DeleteIcon";
import DetailsIcon from "../icons/DetailsIcon";
import ThreeDotsIcon from "../icons/ThreeDotsIcon";
import EditTaskModal from "./EditTaskModal";

const service = new Service();

type Props = {
  item: Task,
  isExternal: boolean,
  onAction?: () => void
};

export default function TaskCommands({ item, isExternal, onAction }: Props) {
  const dispatch = useDispatch();
  const cutItem = useSelector((state: RootState) => state.tasks.cutItem);
  const copyItem = useSelector((state: RootState) => state.tasks.copyItem);
  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const onCut = () => {
    dispatch(clearCommands());
    if (cutItem?.id !== item.id)
      dispatch(setCutItem(item));
    onAction?.();
  };

  const onCopy = () => {
    dispatch(clearCommands());
    if (copyItem?.id !== item.id)
      dispatch(setCopyItem(item));
    onAction?.();
  };

  const onDelete = () => {
    service.deleteTask(item.id)
      .then(() => {
        dispatch(deleteItem(item.id));
        dispatch(changeCount({ list: item.listId, diff: -1 }));
        onAction?.();
      })
      .catch(e => dispatch(setError(e)));
  };

  const onShowMenu = (evt: MouseEvent) => {
    if (!showMenu) {
      setTimeout(() => {
        window.addEventListener("click", () => setShowMenu(false), { once: true });
      }, 0);
    }
    setShowMenu(val => !val);
  };

  return (
    <>
      <div className='p-1 pr-0 realative' onClick={onShowMenu}>
        <ThreeDotsIcon />
        {showMenu &&
          <div className={`absolute bg-white w-24 right-0 z-20 shadow-xl flex flex-col divide-y divide-slate-200 text-xs cursor-pointer`}>
            {isExternal &&
              <div className='px-2 py-3 flex gap-2' onClick={() => setShowDetails(true)}>
                <DetailsIcon />DETAILS
              </div>
            }
            <div className={`px-2 py-3 flex gap-2 ${cutItem?.id === item.id ? 'text-gray-200' : ''}`} onClick={onCut}>
              <CutIcon />CUT
            </div>
            <div className={`px-2 py-3 flex gap-2 ${copyItem?.id === item.id ? 'text-gray-200' : ''}`} onClick={onCopy}>
              <CopyIcon />COPY
            </div>
            <div className='px-2 py-3 text-red-500 flex gap-2' onClick={onDelete}>
              <DeleteIcon />DELETE
            </div>
          </div>
        }
      </div>
      {showDetails &&
        <EditTaskModal item={item} onClose={() => setShowDetails(false)} />
      }
    </>
  );
}