import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Service } from "../../data/Service";
import { setError } from "../../state/globalSlice";
import { RootState } from "../../state/store";
import { moveItem, setItems } from "../../state/tasksSlice";
import LoadingBar from "../core/LoadingBar";
import PlusFab from "../core/PlusFab";
import Sortable, { ChangeEvent } from "../core/Sortable";
import NewTaskModal from "./NewTaskModal";
import TaskItem from "./TaskItem";

const service = new Service();

export default function Tasks() {
  const dispatch = useDispatch();
  const tasks = useSelector((state: RootState) => state.tasks.items);
  const selectedList = useSelector((state: RootState) => state.lists.items[state.lists.selectedList!]);

  const [isLoading, setIsLoading] = useState(true);
  const [showNewTask, setShowNewTask] = useState(false);

  useEffect(() => {
    dispatch(setItems([]));

    if (selectedList) {
      setIsLoading(true);
      service.getTasks(selectedList.id)
        .then(data => dispatch(setItems(data)))
        .catch(e => dispatch(setError(e)))
        .finally(() => setIsLoading(false));
    }
  }, [selectedList?.id]);

  const onChange = (evt: ChangeEvent) => {
    const id = evt.item.dataset.id!;
    dispatch(moveItem({ from: evt.oldIdx, to: evt.newIdx }));
    service.moveTask(id, selectedList.id, evt.newIdx)
      .catch(e => {
        dispatch(moveItem({ from: evt.newIdx, to: evt.oldIdx }));
        dispatch(setError(e));
      });
  };

  return (
    <>
      <div className="relative w-full flex flex-col">
        {selectedList && <>
          <PlusFab z={11} onClick={() => setShowNewTask(true)} />
          <div className="flex items-center">
            <h1 className="flex-1 text-2xl text-gray-400">Tasks for <span className="text-black">{selectedList.name}</span></h1>
          </div>
        </>}
        {selectedList && isLoading ?
          <LoadingBar /> :
          <Sortable onChange={onChange} className="flex flex-col gap-5 mt-6" options={{ delay: 200, delayOnTouchOnly: true }}>
            {tasks.map(i =>
              <TaskItem key={i.id} item={i} />
            )}
          </Sortable>
        }
      </div>
      {showNewTask &&
        <NewTaskModal onClose={() => setShowNewTask(false)} />
      }
    </>
  );
}