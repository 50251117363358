import React from 'react';

type Props = {
  onClick?: () => void,
};

export default function ThreeDotsIcon({ onClick }: Props) {
  return (
    <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className='cursor-pointer' onClick={onClick}>
      <title>Details</title>
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );
}