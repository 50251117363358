import { useEffect } from 'react';

type Props = {
  content: JSX.Element[] | JSX.Element,
  footer: JSX.Element[] | JSX.Element,
  onClose: () => void,
  width: number,
  height: number
};


export default function Modal({ onClose, content, footer, width, height }: Props) {
  useEffect(() => {
    const callback = (evt: Event) => {
      const keyEvent = evt as KeyboardEvent;
      if (keyEvent.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keyup", callback);
    return () => window.removeEventListener("keyup", callback);
  }, []);

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 z-40 w-full h-full flex justify-center items-center p-6">
      <div className='absolute w-full h-full bg-gray-900 opacity-50' onClick={onClose} />
      <div className='relative p-6 pb-4 w-full bg-white shadow rounded-sm flex flex-col'
        style={{ maxWidth: width + 'rem', minHeight: height + 'rem' }}>
        <div className='flex-1'>
          {content}
        </div>
        <div>
          {footer}
        </div>
      </div>
    </div>
  );
}