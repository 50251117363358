import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpError } from "../../data/Service";
import { clearError, signOut } from "../../state/globalSlice";
import { RootState } from "../../state/store";
import Alert from "./Alert";

export default function ErrorFragment() {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.global.error);
  const navigate = useNavigate();

  let message;
  if (error instanceof HttpError) {
    if (error.code === 401) {
      dispatch(signOut());
      dispatch(clearError());
      navigate('/');
    } else if (error.body) {
      message = error.body.message;
    }
  } else {
    message = error?.message;
  }

  return message
    ? <Alert message={message} onClose={() => dispatch(clearError())} />
    : <></>;
};