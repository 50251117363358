type Props = {
  value: boolean,
  onChange?: (val: boolean) => void
};

export default function CheckBox({ value, onChange }: Props) {
  return (
    <div className={`flex border-2 border-gray-400 rounded-full h-5 w-5 justify-center items-center cursor-pointer ${value ? 'bg-gray-400' : ''}`}
      onClick={() => onChange?.(!value)}>
      {value &&
        <svg width="16" height="16" fill="white" viewBox="3 3 10 10">
          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
        </svg>
      }
    </div>
  );
}