import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Task } from '../data/models';
import { moveElement } from '../tools/helpers';

type StateType = {
  items: Task[],
  cutItem?: Task,
  copyItem?: Task,
  dragItem?: Task
}
const initialState: StateType = {
  items: [],
  cutItem: undefined,
  copyItem: undefined
}

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,

  reducers: {
    setItems: (state, action: PayloadAction<Task[]>) => {
      state.items = action.payload;
    },
    moveItem: (state, action: PayloadAction<{ from: number, to: number }>) => {
      moveElement(state.items, action.payload.from, action.payload.to);
    },
    addItem: (state, action: PayloadAction<{ item: Task, isLast: boolean }>) => {
      const { item, isLast } = action.payload;
      if (isLast) {
        state.items.push(item);
      } else {
        state.items.unshift(item);
      }
    },
    updateItem: (state, action: PayloadAction<Task>) => {
      let idx = state.items.findIndex(e => e.id === action.payload.id);
      state.items[idx] = action.payload;

      if (state.cutItem && state.cutItem.id === action.payload.id)
        state.cutItem = action.payload;
      if (state.copyItem && state.copyItem.id === action.payload.id)
        state.copyItem = action.payload;
    },
    deleteItem: (state, action: PayloadAction<string>) => {
      let idx = state.items.findIndex(e => e.id === action.payload);
      state.items.splice(idx, 1);
    },
    setCutItem: (state, action: PayloadAction<Task>) => {
      state.cutItem = action.payload;
    },
    setCopyItem: (state, action: PayloadAction<Task>) => {
      state.copyItem = action.payload;
    },
    clearCommands: (state) => {
      state.cutItem = undefined;
      state.copyItem = undefined;
    },
    setDragItem: (state, action: PayloadAction<Task>) => {
      state.dragItem = action.payload;
    },
    clearDragItem: (state) => {
      state.dragItem = undefined;
    },
  },
});


export const { setItems, moveItem, addItem, updateItem, deleteItem, setCutItem, setCopyItem, clearCommands, setDragItem, clearDragItem } = tasksSlice.actions;
export default tasksSlice.reducer;