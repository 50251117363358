import React, { useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Signin from './pages/account/Signin';
import Signup from './pages/account/Signup';
import AuthCallback from './pages/account/AuthCallback';
import PublicHome from './pages/PublicHome';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import PrivateHome from './pages/PrivateHome';
import Layout from './pages/Layout';
import Terms from './pages/Terms';
import ResetPassword from './pages/account/ResetPassword';
import NewPassword from './pages/account/NewPassword';
import Alert from './components/core/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './state/store';
import About from './pages/About';
import ErrorFragment from './components/core/ErrorFragment';

export default function App() {
  const NotFound = () => (
    <div className='flex justify-center items-center w-full'>
      <div className='text-center'>
        <h1 className='text-6xl'>404 - Not Found</h1><br />
        <Link to="/" className='text-xl underline'>Home</Link>
      </div>
    </div>
  );

  const Home = () => {
    const isSignedIn = useSelector((state: RootState) => state.global.isSignedIn);
    return isSignedIn
      ? <PrivateHome />
      : <PublicHome />;
  };

  return (
    <>
      <Routes>
        <Route path='/authcallback' element={<AuthCallback />} />
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/about' element={<About />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>

      <ErrorFragment />
    </>
  );
}